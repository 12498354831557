import React from "react";


function ProductSpecs() {
    return (
        <div className="spacification">
            <div className="list">
                <h4>Paper Stock</h4>
                <p>10pt to 28pt (60lb to 400lb). Available in Eco-Friendly Kraft, Bux Board, Cardstock, E-flute Corrugated (Available in white & Brown)</p>
            </div>
            <div className="list">
                <h4>Dimensions</h4>
                <p>Available in any Customized Shape and Size.</p>
            </div>
            <div className="list">
                <h4>Colors</h4>
                <p>CMYK, PMS colors, Full Color</p>
            </div>
            <div className="list">
                <h4>Quantity</h4>
                <p>100 - 700,000</p>
            </div>
            <div className="list">
                <h4>Coating</h4>
                <p> Matte, Gloss, Aqueous Coating and Spot UV </p>
            </div>
            <div className="list">
                <h4>Included Options</h4>
                <p> Gluing, Scoring, Die Cutting, Perforation. </p>
            </div>
            <div className="list">
                <h4>More Options</h4>
                <p>Gold/Silver Foiling,Custom Window Cut Out,  Embossing, PVC Sheet, Raised Ink.</p>
            </div>
            <div className="list">
                <h4>File Upload</h4>
                <p>
                    Bleeding Include <span style={{fontSize: '26px'}}>⅛</span>.
                    Files should be in CMYK colors
                    Send all fonts and fonts in outline form
                    Image Resolution 300 dpi
                    Do not embed images, send all linked artwork.
                </p>
            </div>
            <div className="list">
                <h4>File formats</h4>
                <p>Ai, PSD(with layers), EPS(Vector)
                    Press Ready PDF file</p>
            </div>
            <div className="list">
                <h4>Proof</h4>
                <p>3D Mock-up, Flat View, Free Sample Kit (On Demand)</p>
            </div>
            <div className="list">
                <h4>Turn Around Time</h4>
                <p>Usually 8-10 Business Days, Rush Production is also Available </p>
            </div>
        </div>
    )

}
export default ProductSpecs;