import React from "react";
import Menu from "./menu";
function Header2() {
    return (
        <header className="header product-style">
            <Menu></Menu>
        </header>
    )

}
export default Header2;