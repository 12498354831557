import React from "react";
import Menu from "./menu";

function HeaderCat() {
    
    return (
        <header className="header category-style">
            <Menu></Menu>
        </header>
    )
    

}
export default HeaderCat;