import React from "react";

import Slider from "react-slick";

function Testimonials(props) {

    var settings = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 912,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    return (
        <section className="review">
            <div className="container">
                <h1 className="text-center mt-0 mb-5">Customer Reviews</h1>
                <div className="reviews-list position-relative">
                    <Slider {...settings}>

                        {props.results.map((item, index) => (
                            <div key={item.id + '-test'} className="row-full">

                                <div className="review-inner">
                                    <div className="stars">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <h4 className="review-title">
                                        {item.title}
                                    </h4>
                                    <p>{item.description}</p>
                                    {/* <div className="name">Martin</div> */}
                                </div>
                            </div>
                        ))}


                    </Slider>
                </div>
            </div>
        </section>

    );
}
export default Testimonials;