import React, { useState, useEffect } from "react";
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css';


function QuoteForm(props) {

    const navigate = useNavigate();

    const qty_arr = [
        100,
        150,
        200,
        250,
        500,
        750,
        1000,
        1500,
        2000,
        2500,
        3000,
        4000,
        5000,
        6000,
        7000,
        8000,
        9000,
        10000,
        15000,
        20000,
        30000,
        40000,
        50000
    ];

    const [formData, setFormData] = useState({
        product_name: '',
        box_length: '',
        box_width: '',
        box_depth: '',
        unit: 'Inches',
        color: '',
        qty1: '',
        qty2: '',
        qty3: '',
        box_paper_size: '',
        lamination: '',
        coating: '',
        email: '',
        name: '',
        phone: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        box_width: '',
        box_length: '',
        box_depth: '',
        color: '',
        qty1: '',
        box_paper_size: '',
        name: '',
        email: '',
        phone: ''
    });

    const [isPaymentLoading, setPaymentLoading] = useState(false);
    // const [isSuccess, setIsSuccess] = useState(false);
    const [phone, setPhone] = useState("");

    // The function you pass to useEffect will be called after the component renders.
    useEffect(() => {


    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validation logic
        let formErrors = {};
        
        if(phone === undefined) {
            formErrors.phone = 'Phone is required';
        }
        if(phone === '') {
            formErrors.phone = 'Phone is required';
        }
        // If there are errors, set the state with error messages
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            setPaymentLoading(true);
            try {
                formData.phone = phone;
                formData.product_name = props.results.product_display_name;
                let res = await fetch("https://app.packiya.com/api/quote", {
                    method: "POST",
                    body: JSON.stringify(formData),
                });

                let data = await res.json();
                if (data.success) {
                    console.log("Successful payment")
                    setPaymentLoading(false);
                    navigate('/thank-you');
                    

                } else {
                    setPaymentLoading(false);
                }

            } catch (error) {
                setPaymentLoading(false);
                console.log("Error", error)
            }
        }
    };







    return (
        <div>
            <form method="post" className="mt-4" onSubmit={handleSubmit}>

                <div className="form-group mt-3">

                    <div className="d-flex flex-wrap col-gap-20 position-relative ">
                        <div className={`form-group ${errors.box_length !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Length<sup style={{ color: 'red' }}>*</sup></label>
                            <input type="number" name="box_length" className="form-control" value={formData.box_length} onChange={handleChange} required />
                        </div>
                        <div className={`form-group ${errors.box_width !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Width<sup style={{ color: 'red' }}>*</sup></label>
                            <input type="number" name="box_width" className="form-control" value={formData.box_width} onChange={handleChange} required />
                        </div>
                        <div className={`form-group ${errors.box_depth !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Depth<sup style={{ color: 'red' }}>*</sup></label>
                            <input type="number" name="box_depth" className="form-control" value={formData.box_depth} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label className="control-label">Unit<sup style={{ color: 'red' }}>*</sup></label>
                            <select name="unit" className="form-control" required value={formData.unit} onChange={handleChange}>
                                <option value="Inches">Inches</option>
                                <option value="CM">CM</option>
                                <option value="MM">MM</option>
                            </select>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap col-gap-20 position-relative ">

                        <div className={`form-group ${errors.box_paper_size !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Choose Card Stock<sup style={{ color: 'red' }}>*</sup></label>
                            <select name="box_paper_size" className="form-control" value={formData.box_paper_size} onChange={handleChange} required>
                                <option value="">Choose Card Stock</option>
                                <option value="12pt / 270 gsm Card Stock">12pt / 270 gsm Card Stock</option>
                                <option value="14pt / 315 gsm Card Stock">14pt / 315 gsm Card Stock</option>
                                <option value="16pt / 360 gsm Card Stock">16pt / 360 gsm Card Stock</option>
                                <option value="18pt / 405 gsm Card Stock">18pt / 405 gsm Card Stock</option>
                                <option value="20pt / 450 gsm Card Stock">20pt / 450 gsm Card Stock</option>
                                <option value="24pt / 540 gsm Card Stock">24pt / 540 gsm Card Stock</option>
                                <option value="Kraft Stock">Kraft Stock</option>
                                <option value="Corrugated Stock">Corrugated Stock</option>
                                <option value="Rigid Stock">Rigid Stock</option>
                            </select>
                        </div>
                        <div className={`form-group ${errors.color !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Color<sup style={{ color: 'red' }}>*</sup></label>
                            <select name="color" className="form-control" value={formData.color} onChange={handleChange} required>
                                <option value="">Color</option>
                                <option value="none">None</option>
                                <option value="1 Color">1 Color</option>
                                <option value="2 Color">2 Color</option>
                                <option value="3 Color">3 Color</option>
                                <option value="4 Color">4 Color</option>
                                <option value="4/1 Color">4/1 Color</option>
                                <option value="4/2 Color">4/2 Color</option>
                                <option value="4/3 Color">4/3 Color</option>
                                <option value="4/4 Color">4/4 Color</option>
                            </select>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap col-gap-20 position-relative ">


                        <div className={`form-group ${errors.qty1 !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Quantity 1<sup style={{ color: 'red' }}>*</sup></label>
                            <select name="qty1" className="form-control" value={formData.qty1} onChange={handleChange} required>
                                <option value="">Qty 1</option>
                                {qty_arr.map((item) => {
                                    return <option value={item} key={item}>{item}</option>
                                })}
                            </select>
                        </div>
                        <div className={`form-group `}>
                            <label className="control-label">Quantity 2</label>
                            <select name="qty2" className="form-control" value={formData.qty2} onChange={handleChange}>
                                <option value="">Qty 2</option>
                                {qty_arr.map((item) => {
                                    return <option value={item} key={item}>{item}</option>
                                })}
                            </select>
                        </div>
                        <div className={`form-group `}>
                            <label className="control-label">Quantity 3</label>
                            <select name="qty3" className="form-control" value={formData.qty3} onChange={handleChange}>
                                <option value="">Qty 3</option>
                                {qty_arr.map((item) => {
                                    return <option value={item} key={item}>{item}</option>
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap col-gap-20 position-relative ">

                        <div className={`form-group ${errors.lamination !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Lamination<sup style={{ color: 'red' }}>*</sup></label>
                            <select name="lamination" className="form-control" value={formData.lamination} onChange={handleChange} required>
                                <option value="">Choose Lamination</option>
                                <option value="Matte">Matte</option>
                                <option value="Glossy">Glossy</option>
                                <option value="Anti Scratch Soft Touch">Anti Scratch Soft Touch</option>
                            </select>
                        </div>
                        <div className={`form-group `}>
                            <label className="control-label">Coating</label>
                            <select name="coating" className="form-control" value={formData.coating} onChange={handleChange}>
                                <option value="">None</option>
                                <option value="Gold Foiling">Gold Foiling</option>
                                <option value="Silver Foiling">Silver Foiling</option>
                                <option value="Embossing">Embossing</option>
                                <option value="Spot UV">Spot UV</option>
                                <option value="Window Patching">Window Patching</option>
                                <option value="Raised Ink">Raised Ink</option>
                            </select>
                        </div>
                    </div>
                    

                    <div className="d-flex flex-wrap col-gap-20 position-relative ">
                        <div className={`form-group ${errors.name !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Name<sup style={{ color: 'red' }}>*</sup></label>
                            <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className={`form-group ${errors.email !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Email<sup style={{ color: 'red' }}>*</sup></label>
                            <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className={`form-group ${errors.phone !== '' ? 'has-error' : ''} `}>
                            <label className="control-label">Phone<sup style={{ color: 'red' }}>*</sup></label>
                            <PhoneInput
                                placeholder="Enter phone number"
                                defaultCountry="GB"
                                international={true}
                                value={phone}
                                onChange={setPhone} required />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label">Message</label>
                        <textarea name="message" className="form-control" rows={3} value={formData.message} onChange={handleChange}></textarea>
                    </div>


                    <br />
                    <button type="submit" className="btn btn-packiya btn-block">
                        {isPaymentLoading ? "Loading..." : "Send Inquiry"}
                    </button>
                    <br />
                    {/* {isSuccess ?
                        <div className="alert alert-success" role="alert">
                            Inquiry sent we will contact you shortly. Thanks!
                        </div>
                        :
                        ''
                    } */}


                </div>
            </form>
        </div>

    );
}
export default QuoteForm;