import React from "react";

import bannerImage from './../assets/img/packia-banner.png';

import { isMobile } from 'react-device-detect';

import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function HeroSection() {
    if (isMobile) {
        return (
            <section className="hero">
                <div className="container h-100">
                    <div className="hero-inner h-100 position-relative">

                        <div className="static-banner">
                            <img src={bannerImage} alt="Packia" className="img-fluid" />
                        </div>
                        <div className="floating-center-content">
                            <h1 className="hero-title">
                                Custom
                                Boxes &
                                Packaging Design
                            </h1>
                            <Link className="btn btn-primary get-started" to="/product/product-boxes">Get Started</Link>
                        </div>
                    </div>
                </div>

            </section>

        );
    } else {
        return (
            <section className="hero">
                <div className="container h-100">
                    <div className="hero-inner h-100">
                        <div className="row h-100 align-items-center justify-content-center">
                            <div className="col-12 col-md-6">
                                <h1 className="hero-title">
                                    #1 Custom
                                     Printed Packaging
                                      Boxes
                                </h1>
                                <p className="hero-desc">
                                    Your #1 destination for custom printed packaging boxes. Grow your brand with Packiya's custom printed boxes! 
                                    Stand out, boost sales, and wow your customers with every delivery. Free design support available.
                                </p>
                                <Link className="btn btn-primary get-started" to="/product/product-boxes">Get Started</Link>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="static-banner">
                                    <LazyLoadImage src={bannerImage} alt="Packia" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        );
    }

}
export default HeroSection;